/* Defining helper functions/objects */
// changeImpactedElement: function that removes the provided class(es) and adds the provided class(es) to Hosted Payment Fields element
function changeImpactedElement(tagId, removeClass, addClass) {
  removeClass = removeClass || "";
  addClass = addClass || "";
  $("[data-bluesnap=" + tagId + "]")
      .removeClass(removeClass)
      .addClass(addClass);
}

var bsObj = {
  onFieldEventHandler: {
    // tagId returns: "ccn", "cvv", "exp" 
    onFocus: function(tagId) {
      // Handle focus
      changeImpactedElement(tagId, "hosted-field-valid hosted-field-invalid", "hosted-field-focus"); 
    },
    onBlur: function(tagId) {
      // Handle blur
      changeImpactedElement(tagId, "hosted-field-focus"); 
    },
    onError: function(tagId, errorCode, errorDescription) {
      // Handle a change in validation
      changeImpactedElement(tagId, "hosted-field-valid hosted-field-focus", "hosted-field-invalid");     
      $("#" + tagId + "-help").removeClass('helper-text-green').text(errorCode + " - " + errorDescription); 
    },
    /*errorCode returns:
        "001" --> "Please enter a valid credit card number";
        "002" --> "Please enter the CVV/CVC of your card";
        "003" --> "Please enter your credit card's expiration date";
        "22013" --> "CC type is not supported by the merchant"; 
        "14040" --> " Token is expired";
        "14041" --> " Could not find token";
        "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
        "400" --> "Session expired please refresh page to continue";
        "403", "404", "500" --> "Internal server error please try again later"; 
    */

    /* errorDescription is optional. Returns BlueSnap's standard error description */
    onType: function (tagId, cardType) {
      console.log("--DEBUG-- onType cardType=" + cardType);
    }, /* cardType will give card type, and only applies to ccn: CarteBleue, Visa, MasterCard, AmericanExpress, Discover, DinersClub, JCB, Solo, MaestroUK, ChinaUnionPay */
    onValid: function (tagId) {
      console.log("--DEBUG-- onValid");
    }, // Handle a change in validation
  },
  style: {
    // Styling all inputs
    "input": {
      "font-size": "14px",
      "font-family": "Helvetica Neue,Helvetica,Arial,sans-serif",
      "line-height": "1.42857143",
      "color": "#555"
    },

    // Styling Hosted Payment Field input state
    ":focus": {
      "color": "#555"
    }
  },
  ccnPlaceHolder: "0000 0000 0000 0000", //for example
  cvvPlaceHolder: "000", //for example
  expPlaceHolder: "MM/YY", //for example
  expDropDownSelector: false //set to true for exp. date dropdown
};

